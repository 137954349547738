import { PageProps } from "gatsby";
import React from "react";

import { NamesOfNotesAndIntervals } from "../../components/pages/NamesOfNotesAndIntervals";

import { dnProps } from "../../js/utils";

const NamesOfNotesAndIntervalsPage = (props: PageProps) => (
  <NamesOfNotesAndIntervals {...{ ...dnProps, ...props }} />
);

export default NamesOfNotesAndIntervalsPage;
